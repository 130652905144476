import moment from "moment";
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { HostbiAccount, HostbiAccountDomain } from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { UiModalboxService } from 'src/app/library/components/ui-modalbox';
import { HostbiDomainsService } from 'src/app/modules/hostbi/services/hostbi-domains.service';
import { HostbiMonitoringService } from 'src/app/modules/hostbi/services/hostbi-monitoring.service';

// Components
import { HostbiAccountDomainAddComponent } from 'src/app/modules/hostbi/components/account-domain-add/account-domain-add.component';
import { HostbiAccountPlanComponent } from 'src/app/modules/hostbi/components/account-plan/account-plan.component';
import { HostbiAccountDeleteComponent } from '../../components/account-delete/account-delete.component';
import { HostbiAccountDomainRecordFormComponent } from "../../components/account-domain-record-form/account-domain-record-form.component";
import { UiAlertRef } from "src/app/library/components/ui-alert";
import { HostbiDomainSslStatusComponent } from "../../components/domain-ssl-status/domain-ssl-status.component";

@Component({
  selector: 'app-hostbi-monitoring-details',
  templateUrl: './monitoring-details.component.html',
  styleUrls: ['./monitoring-details.component.scss']
})
export class HostbiMonitoringDetailsComponent implements OnInit {
  public id = 0;
  public report = new HostbiAccount();
  public livetraffic: Array<any> = [];
 
  public errors = {
    load: {
      title: 'Cargando datos',
      message: 'Estamos cargando la información de la cuenta.',
      icon: 'public',
      loading: true,
    },
    error: {
      title: 'Cuenta de alojamiento',
      message: 'No es posible obtener los datos de la cuenta en este momento. Estamos trabajando para solucionar el inconveniente.',
      icon: 'public',
    },
    invalid: {
      title: 'Cuenta de alojamiento',
      message: 'Al aprecer, no se accedió correctamente a los detalles de la cuenta. Podes volver al listado de cluentas y seleccionarla nuevamente.',
      icon: 'report_problem',
    }
  };

  constructor(
    private uiService: UiService,
    private route: ActivatedRoute,    
    private uiModalboxService: UiModalboxService,
    private hostbiDomainsService: HostbiDomainsService,
    private hostbiMonitoringService: HostbiMonitoringService,
  ) {
    this.id = Number(this.route.snapshot.paramMap.get('id') ?? 0);
  }

  ngOnInit(): void {
    this.uiService.screenLoader(this.errors.load);

    if(this.id > 0){
      const initData = [];
  
      initData.push(this.hostbiMonitoringService.whitWorkspace().report(this.id));
      initData.push(this.hostbiMonitoringService.whitWorkspace().livetraffic(this.id));
      
      Promise.all(initData).then(values => {        
        this.report = values[0].result;
        this.livetraffic = values[1].result;        
      }).catch(() => {
        this.uiService.loader(false);
        this.uiService.screenLoader(this.errors.error);
      });
    } else {
      this.uiService.screenLoader(this.errors.invalid);
    }
  }

  // Actions

  ipActivity(action: 'lock'|'unlock'): void {
    this.uiService.loader(true);

    this.hostbiMonitoringService.whitWorkspace().ipActivity(action, this.id).then((success: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Ip desbloqueada', 'IP desbloqueada correctamente.', 'success');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Error', error.message, 'error');
    });
  }
}
