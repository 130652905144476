
<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">Datos de facturación</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card>
        <div class="ui-card-content">
            <billing-data-form [data]="billingData" [(update)]="billingDataUpdate"></billing-data-form>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" (click)="update()">Actualizar datos</ui-button>    
        </div>
    </ui-card>
</ui-content>