import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Utils
import { RoutingGuard } from 'src/app/platform/functions/routing.guard';

// Views
import { BillingBillingComponent } from 'src/app/modules/billing/views/billing/billing.component';

const routes: Routes = [
  { path: '', redirectTo: '/billing/invoices', pathMatch: 'full' },
  { path: 'billing/resume', component: BillingBillingComponent, canActivate: [RoutingGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule { }
