import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { LibraryModule } from 'src/app/library/library.module'
import { HostbiRoutingModule } from 'src/app/modules/hostbi/hostbi-routing.module';
import { BillingModule } from 'src/app/modules/billing/billing.module';
import { UserModule } from 'src/app/modules/user/user.module';

// Services
import { HostbiAccountsService } from 'src/app/modules/hostbi/services/hostbi-accounts.service';
import { HostbiPlansService } from 'src/app/modules/hostbi/services/hostbi-plans.service';
import { HostbiServersService } from 'src/app/modules/hostbi/services/hostbi-servers.service';
import { HostbiServicesService } from 'src/app/modules/hostbi/services/hostbi-services.service';
import { HostbiDomainsService } from 'src/app/modules/hostbi/services/hostbi-domains.service';

// Components
import { HostbiAccountComponent } from 'src/app/modules/hostbi/components/account/account.component';
import { HostbiAccountAddComponent } from 'src/app/modules/hostbi/components/account-add/account-add.component';
import { HostbiAccountAdminComponent } from 'src/app/modules/hostbi/components/account-admin/account-admin.component';
import { HostbiAccountPlanComponent } from 'src/app/modules/hostbi/components/account-plan/account-plan.component';
import { HostbiAccountDeleteComponent } from 'src/app/modules/hostbi/components/account-delete/account-delete.component';
import { HostbiAccountIssuesComponent } from 'src/app/modules/hostbi/components/account-issues/account-issues.component';
import { HostbiEmailRecordComponent } from 'src/app/modules/hostbi/components/email-record/email-record.component';
import { HostbiDomainRecordComponent } from 'src/app/modules/hostbi/components/domain-record/domain-record.component';
import { HostbiDomainSslStatusComponent } from 'src/app/modules/hostbi/components/domain-ssl-status/domain-ssl-status.component';
import { HostbiAccountDomainAddComponent } from 'src/app/modules/hostbi/components/account-domain-add/account-domain-add.component';
import { HostbiAccountDomainRecordFormComponent } from 'src/app/modules/hostbi/components/account-domain-record-form/account-domain-record-form.component';
import { HostbiServerRecordComponent } from 'src/app/modules/hostbi/components/server-record/server-record.component';

// Views
import { HostbiDashboardComponent } from 'src/app/modules/hostbi/views/dashboard/dashboard.component';
import { HostbiAccountsManageComponent } from 'src/app/modules/hostbi/views/accounts-manage/accounts-manage.component';
import { HostbiAccountDetailsComponent } from 'src/app/modules/hostbi/views/account-details/account-details.component';
import { HostbiServersManageComponent } from 'src/app/modules/hostbi/views/servers-manage/servers-manage.component';
import { HostbiMonitoringDetailsComponent } from 'src/app/modules/hostbi/views/monitoring-details/monitoring-details.component';

@NgModule({
  declarations: [
    HostbiAccountComponent,
    HostbiAccountAddComponent,
    HostbiAccountAdminComponent,
    HostbiAccountPlanComponent,
    HostbiAccountDeleteComponent,
    HostbiAccountIssuesComponent,
    HostbiEmailRecordComponent,
    HostbiDomainRecordComponent,
    HostbiDomainSslStatusComponent,
    HostbiAccountDomainAddComponent,
    HostbiAccountDomainRecordFormComponent,
    HostbiServerRecordComponent,

    HostbiDashboardComponent,
    HostbiAccountsManageComponent,
    HostbiAccountDetailsComponent,
    HostbiServersManageComponent,
    HostbiMonitoringDetailsComponent
  ],
  imports: [
    CommonModule,
    LibraryModule,
    HostbiRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BillingModule,
    UserModule,
  ],
  providers: [
    HostbiAccountsService,
    HostbiPlansService,
    HostbiServersService,
    HostbiServicesService,
    HostbiDomainsService,
  ]
})

export class HostbiModule { }
