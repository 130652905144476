import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { LibraryModule } from 'src/app/library/library.module'
import { UserRoutingModule } from 'src/app/modules/user/user-routing.module'

// Services
import { SupportService } from 'src/app/modules/user/services/support.service';
import { UserService } from 'src/app/modules/user/services/user.service';
import { UsersService } from 'src/app/modules/user/services/users.service';
import { NotificationsService } from 'src/app/modules/user/services/notifications.service';

// Components
import { UserBoxComponent } from 'src/app/modules/user/components/user-box/user-box.component';
import { UserRecordComponent } from 'src/app/modules/user/components/user-record/user-record.component';
import { UserAccountPasswordComponent } from 'src/app/modules/user/components/account-password/account-password.component';

// Views
import { UsersComponent } from 'src/app/modules/user/views/users/users.component';
import { UserAccountComponent } from 'src/app/modules/user/views/account/account.component';
import { SupportComponent } from 'src/app/modules/user/views/support/support.component';
import { SupportFormComponent } from 'src/app/modules/user/views/support-form/support-form.component';
import { SupportPostComponent } from 'src/app/modules/user/views/support-post/support-post.component';
import { SupportCategoryComponent } from 'src/app/modules/user/views/support-category/support-category.component';

@NgModule({
  declarations: [ 
    UserBoxComponent,
    UserRecordComponent,
    UserAccountPasswordComponent,
    
    UsersComponent,
    UserAccountComponent,

    SupportComponent,    
    SupportFormComponent,
    SupportPostComponent,
    SupportCategoryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LibraryModule,
    UserRoutingModule,
  ],
  exports:[
    UserBoxComponent,
    UserRecordComponent,
  ],
  providers: [
    SupportService,
    UserService,
    UsersService,
    NotificationsService
  ]
})
export class UserModule { }
