
// Models
import { ModelDefault } from "@app/library/models/commons";

export class HostbiMonitoring extends ModelDefault {
    id = 0;
    workspace = '';
    account_id = 0;
    domain = '';
    user = '';
    url = '';
    scheme = '';
}

export class HostbiMonitoringLiveTraffic extends ModelDefault {
    url = '';
	referer = '';
	timeAgo = '';
	action = '';
	description = '';
	browser = new HostbiMonitoringTrafficBrowser();		
	location = new HostbiMonitoringTrafficLocation();

    protected override filter(key: string, value: any){
        if(key === 'browser'){
            return new HostbiMonitoringTrafficBrowser(value);
        } else if(key === 'location'){
            return new HostbiMonitoringTrafficLocation(value);
        }

        return super.filter(key, value);
    }
}

export class HostbiMonitoringTrafficBrowser extends ModelDefault {
    name = '';
	version = '';
	platform = '';
	is_mobile = '';
}

export class HostbiMonitoringTrafficLocation extends ModelDefault {
    ip = '';
	city = '';
	region = '';
	country_name = '';
	country_code = '';
	lat = 0;
	lon = 0;
}

export class HostbiMonitoringReport extends ModelDefault {
    status = false;
	userIp = '';
	updates = new HostbiMonitoringUpdates();
	activity: Array<HostbiMonitoringActivity> = [];
	filesModified: Array<HostbiMonitoringFileModified> = [];
	loginsFailed: Array<HostbiMonitoringLoginFailed> = [];
	countriesBlocked: Array<HostbiMonitoringCountryBlocked> = [];
	ipsBlocked: Array<HostbiMonitoringIpBlocked> = [];
	scanIssues: Array<HostbiMonitoringScanIssue> = [];

    protected override filter(key: string, value: any){
        if(key === 'activity'){
            return new HostbiMonitoringActivity(value);
        } else if(key === 'filesModified'){
            return new HostbiMonitoringFileModified(value);
        } else if(key === 'loginsFailed'){
            return new HostbiMonitoringLoginFailed(value);
        } else if(key === 'countriesBlocked'){
            return new HostbiMonitoringCountryBlocked(value);
        } else if(key === 'ipsBlocked'){
            return new HostbiMonitoringIpBlocked(value);
        } else if(key === 'scanIssues'){
            return new HostbiMonitoringScanIssue(value);
        }
        
        return super.filter(key, value);
    }
}

export class HostbiMonitoringUpdates extends ModelDefault {
    core = '';
	plugins = [];
	themes = [];
}

export class HostbiMonitoringActivity extends ModelDefault {

}

export class HostbiMonitoringFileModified extends ModelDefault {

}

export class HostbiMonitoringLoginFailed extends ModelDefault {

}

export class HostbiMonitoringCountryBlocked extends ModelDefault {
    
}

export class HostbiMonitoringIpBlocked extends ModelDefault {

}

export class HostbiMonitoringScanIssue extends ModelDefault {

}
