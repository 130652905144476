<ui-header>
    <ui-title>Movimientos de la cuenta</ui-title>
</ui-header>

<ui-content>
    <div class="ui-movements">
        <ng-container *ngIf="movementsData.length > 0">
            <div class="ui-movements-head">
                <div class="ui-head-name-el">Detalle</div>
                <div class="ui-head-movement-el">Movimiento</div>
                <div class="ui-head-balance-el">Saldo</div>
            </div>

            <div class="ui-movements-list">
                <billing-movement [movement]="movement" [pay]="movementsPay" *ngFor="let movement of movementsData"></billing-movement>
            
                <ui-spinner-layer *ngIf="movementsLoading"></ui-spinner-layer>
            </div>

            <div class="ui-movements-pagination">
                <ui-pagination [fetch]="movementsFetch" [data]="movementsPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
            </div>
        </ng-container>
        
        <ui-screen-loader *ngIf="movementsData.length == 0"></ui-screen-loader>
    </div>
</ui-content>

