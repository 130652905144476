import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Utils
import { isPath } from 'src/app/library/utils/functions';
import { track } from 'src/app/platform/functions/marketing';
import { appCurrent, platformUrl } from 'src/app/platform/functions/platform';

// Models
import { User } from 'src/app/modules/user/models/user';
import { HttpResponseData } from 'src/app/library/models/utils';
import { BillingOrderDetail } from 'src/app/modules/billing/models/orders';
import { BillingData, BillingDataUpdate } from 'src/app/modules/billing/models/billing';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';
import { BillingOrdersService } from 'src/app/modules/billing/services/orders.service';
import { WorkspacesService } from 'src/app/modules/workspaces/services/workspaces.service';

// Components
import { BillingDataFormComponent } from '@modules/billing/components/data-form/data-form.component';

@Component({
  selector: 'billing-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class BillingOrdersComponent implements OnInit {
  @Input() data: any = {};
  @Input() finish: (response: any) => any = (paymentLink: string) => { 
    localStorage.removeItem('order-new');
    localStorage.removeItem('order-new-date');
    
    if(this.orderType === 'billeable'){
      this.step = 4;
      
      window.open(paymentLink, '_blank');
      
      this.uiService.screenLoader({
        title: '¡Todo listo!',
        message: 'Vamo a abrir una nueva pestaña para que puedas realizar el pago.',
        icon: 'verified',
        actions: [
          { name: 'Pagar', action: () => { window.open(paymentLink, '_blank'); } },
          { name: this.isModalboxClose ? 'Volver' : 'Ir a mi cuenta', action: () => { 
              if(this.isModalboxClose) {
                this.data.close();
              } else {
                window.location.href = platformUrl();
              }
            }
          }
        ]
      });
    } else {
      if(this.isModalboxClose) {
        this.data.close();
      } else {
        window.location.href = platformUrl();
      }
    }
  };
  
  public userData: User = new User();
  public billingData: BillingData = new BillingData();
  public billingDataUpdate: () => Promise<BillingDataUpdate> = () => new Promise((resolve) => { resolve(new BillingDataUpdate('', this.billingData)); });

  @ViewChild(BillingDataFormComponent) billingForm!: BillingDataFormComponent;

  public orderData: Array<BillingOrderDetail> = [];  

  public params: any;
  public session: any;
  public appCurrent: any = appCurrent;

  public step = 1;
  public screen = 'login';
  public load = false;

  public url = platformUrl('order/new');

  constructor(
    private router: Router,
    private uiService: UiService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private billingAccountingService: BillingAccountingService,
    private workspacesService: WorkspacesService,
    private billingOrdersService: BillingOrdersService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    });

    this.session = this.authService.sessionGet();
    this.userData = this.session.user;
  }

  get orderType(): 'free'|'demo'|'billeable' {
    let type: 'free'|'demo'|'billeable' = 'demo';

    this.orderData.forEach((orderDetail) => {
      if(orderDetail.amount > 0){
        type = 'billeable';
      }
    });
    
    return type;
  }
  
  get orderResume(): { subtotal: number; iva: number; total: number } {
    let subtotal = 0;
    
    this.orderData.forEach((orderDetail) => {
      subtotal = subtotal + orderDetail.amount;
    });
    
    return {
      subtotal,
      iva: (subtotal * 0.21),
      total: subtotal + (subtotal * 0.21),
    };
  }

  get isModalboxClose() : boolean {
    return this.data.modalbox && typeof this.data.close !== 'undefined';
  }

  ngOnInit(): void {
    if(typeof this.data.modalbox === 'undefined' || this.data.modalbox === false){
      const advance = new Promise((resolve) => { 
        const orderId = Number(localStorage.getItem('order-id'));
        const orderPaymentLink = localStorage.getItem('order-payment-link');
        
        if(orderId  > 0){
          resolve(false);

          this.uiService.loader(true);

          this.billingOrdersService.get(Number(orderId)).then((success: HttpResponseData) => {
            this.uiService.loader(false);

            if(!success.result.expired && orderPaymentLink !== ''){
              this.orderData = success.result.details;
              
              this.finish(orderPaymentLink);
            } else {
              resolve(true);
              localStorage.removeItem('order-id');
              localStorage.removeItem('order-payment-link');
            }
          }).catch(() => {
            resolve(true);
            
            this.uiService.loader(false);
          });
        }
      });
      
      advance.then((status) => {
        if(status){
          const orderLocal = JSON.parse(localStorage.getItem('order-new') ?? '[]');
          const orderTime = Number(localStorage.getItem('order-new-date') ?? 0);
  
          if(orderLocal.length > 0){
            if(orderTime > 0 && new Date(Date.now() - (60 * 60 * 24 * 1000)).getTime() > orderTime){
              localStorage.removeItem('order-new');
              localStorage.removeItem('order-new-date');
            } else {
              this.order(orderLocal, false);
            }
          }
        }
      });
    }
    
    /**
      * Detect the current screen according to the route
      */
    if (isPath('/order/new')) {
      this.screen = 'login';
    } else if (isPath('/order/new/recover')) {
      this.screen = 'recover';
    } else if (isPath('/order/new/restore')) {
      if (this.params.token !== '' && typeof this.params.token !== 'undefined') {
        this.screen = 'restore';
      } else {
        this.router.navigate(['/order/new']);
      }
    } else if (isPath('/order/new/workspaces')) {
      if (this.userData.isSet() && !this.userData.workspace.isSet()) {
        this.screen = 'workspaces';
      } else {
        this.router.navigate(['/order/new']);
      }
    } else if (isPath('/order/new/activation')) {
      this.screen = 'activation';
    }
  }

  close(){
    if(this.data.modalbox ?? false){
      localStorage.removeItem('order-new');
      localStorage.removeItem('order-new-date');

      if(typeof this.data.close !== 'undefined'){
        this.data.close();
      }
    }
  }
  
  order(order: Array<BillingOrderDetail>, save = true): void {
    if(order.length > 0){
      this.orderData = order;

      if(save && (typeof this.data.modalbox === 'undefined' || this.data.modalbox === false)){
        localStorage.setItem('order-new', JSON.stringify(this.orderData));
        localStorage.setItem('order-new-date', String(Date.now()));
      }

      if(this.userData.isSessionSet()){
        this.step = 3;
        this.login();
      } else {
        this.step = 2;
      }
    }
  }

  login(): void {
    if(this.userData.isSessionSet()){
      if(this.orderType === 'billeable'){
        this.step = 3;
        this.uiService.loader(true);

        this.billingAccountingService.get().then((success: HttpResponseData) => {
          this.uiService.loader(false);

          this.billingData = success.result;
        }).catch(() => {
          this.uiService.loader(false);
        });
      } else {
        this.step = 3;
        this.process();
      }
    }
  }

  get isActionable(): boolean{
    if(typeof this.billingForm !== 'undefined'){
      return this.billingForm.formData.valid === true
    } 

    return false;
  }
  process(){
    this.load = true;

    this.uiService.screenLoader({
      title: 'Creando cuenta',
      message: 'Estamos procesando el alta tu cuenta, te pedimos que aguardes un momento...',
      icon: 'check_small',
      loading: true,
    });
    
    if(this.orderType === 'billeable' && !this.billingData.isSet()){
      this.billingDataUpdate().then((billingData) => {
        this.billingData = billingData.data;

        this.generate();
      }).catch((error) => {
        this.load = false;
        this.uiService.snackbar(error.message);
      });
    } else {
      this.generate();
    }
  }

  generate(){
    this.workspacesService.orderGenerate({
      type: this.orderType,
      details: this.orderData,
      return_url: platformUrl(),
    }).then((success: HttpResponseData) => {
      this.load = false;

      console.log(success);

      localStorage.setItem('order-id', success.result.order.id);
      localStorage.setItem('order-payment-link', success.result.paymentLink);

      this.finish(success.result.paymentLink);

      track('order_register_checkout');
    }).catch((error: HttpResponseData) => {
      this.load = false;

      console.log(error);

      this.uiService.snackbar('Estamos experimentando algunas dificultades en este momento. Te pedimos que intentes nuevamente.');
    });
  }
}
