<ui-card>
    <div class="ui-order-el" [ngClass]="{'expired': (order.status === 'expired')}" (click)="details()">
        <div 
            class="ui-status-el" 
            [ngClass]="{'pending': order.isPending, 'expired': order.status === 'expired'}"
            [matTooltip]="order.statusName"
        >
            <ui-icon name="check_circle" type="outlined" *ngIf="order.status === 'paid'"></ui-icon>
            <ui-icon name="history_toggle_off" type="outlined" *ngIf="order.status === 'pending'"></ui-icon>
            <ui-icon name="block" type="outlined" *ngIf="order.status === 'expired'"></ui-icon>
        </div>

        <div class="ui-data-el">
            <span class="ui-title-el">{{order.number}}</span>
            <span class="ui-legend-el">{{order.createdAt | date: 'dd/MM/YYYY'}} <ui-badge>{{order.typeName}}</ui-badge></span>
        </div>

        <div class="ui-resume-el">
            <span class="ui-title-el">$&nbsp;{{order.total | number : '1.2-2'}}</span>
            <span class="ui-legend-el" *ngIf="order.totalPending === 0">Abonada</span>
            <span class="ui-legend-el pending" matTooltip="Pendiente de pago">
                <ng-container *ngIf="order.isPending">$&nbsp;{{order.totalPending | number : '1.2-2'}} pendiente</ng-container>
            </span>
        </div>
    </div>

    <div class="ui-actions-el">
        <ui-button  (click)="pay(order)" *ngIf="order.isPending && !balance.debitStatus" matTooltip="Pagar {{order.typeName}}">
            <ui-icon name="attach_money" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-button class="ui-menu-el">
            <ui-icon name="more_vert" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>
    </div>
</ui-card>