import { Component, Input, OnInit, Type } from '@angular/core';

// Utils
import { UiModalbox } from 'src/app/library/components/ui-modalbox';

// Models
import { BillingOrder } from 'src/app/modules/billing/models/orders';
import { BillingBalance } from 'src/app/modules/billing/models/billing';

// Services
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';

@Component({
  selector: 'app-billing-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class BillingOrderDetailsComponent implements OnInit {
  @Input() order: BillingOrder = new BillingOrder();
  @Input() pay: (order: BillingOrder) => void = (): void => { };

  public balance: BillingBalance = new BillingBalance();

  constructor(
    private uiModalbox: UiModalbox,
    private billingAccountingService: BillingAccountingService
  ) {
    this.billingAccountingService.balanceData.subscribe((balance) => {
      this.balance = balance;
    });
  }

  ngOnInit(): void { }

  close(){
    this.uiModalbox.close();
  }
}
