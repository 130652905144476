import { camelcase } from "src/app/library/utils/functions";

export class ModelDefault{
    constructor(parameters?: Record<string, any>) {
        if (typeof parameters !== 'undefined') {
            for (const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[this.camelcase(key)] = this.filter(this.camelcase(key), value);
                }
            }
        }
    }

    protected filter(key: string, value: any) {
        return value;
    }

    private camelcase(string: string): string {
        return string.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
    }
}
export class CommonsLocation {
    countryId = 0;
    countryName = '';

    stateId = 0;
    stateName = '';

    countyId = 0;
    countyName = '';

    cityId = 0;
    cityName = '';
    zipCode = '';

    name = '';
    
    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined' && parameters !== null){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = this.filter(camelcase(key), value);
                }
            }
        }
    }

    private filter(key: string, value: any){
        if(['countryId', 'stateId', 'countyId', 'cityId'].includes(key)){
            return Number(value);
        }

        return value;
    }
}

export class CommonsCurrency {
    id = '';
    name = '';
    symbol = '';

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined' && parameters !== null){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = value;
                }
            }
        }
    }
}