import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { AuthModule } from 'src/app/modules/auth/auth.module';
import { UserModule } from 'src/app/modules/user/user.module';
import { LibraryModule } from 'src/app/library/library.module'
import { BillingRoutingModule } from 'src/app/modules/billing/billing-routing.module';
import { HostbiAccountOrderComponent } from 'src/app/modules/hostbi/components/account-order/account-order.component';

// Services
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';
import { BillingInvoicesService } from 'src/app/modules/billing/services/invoices.service';
import { BillingServicesService } from 'src/app/modules/billing/services/services.service';
import { BillingMovementsService } from 'src/app/modules/billing/services/movements.service';
import { BillingOrdersService } from 'src/app/modules/billing/services/orders.service';
import { BillingPaymentsService } from 'src/app/modules/billing/services/payments.service';

// Components
import { BillingResumeComponent } from 'src/app/modules/billing/components/resume/resume.component';
import { BillingServiceComponent } from 'src/app/modules/billing/components/service/service.component';
import { BillingMovementComponent } from 'src/app/modules/billing/components/movement/movement.component';
import { BillingDataFormComponent } from 'src/app/modules/billing/components/data-form/data-form.component';
import { BillingDataUpdateFormComponent } from 'src/app/modules/billing/components/data-update-form/data-update-form.component';
import { BillingInvoiceComponent } from 'src/app/modules/billing/components/invoice/invoice.component';
import { BillingInvoiceDetailsComponent } from 'src/app/modules/billing/components/invoice-details/invoice-details.component';
import { BillingOrderComponent } from 'src/app/modules/billing/components/order/order.component';
import { BillingOrderDetailsComponent } from 'src/app/modules/billing/components/order-details/order-details.component';
import { BillingOrderFormComponent } from 'src/app/modules/billing/components/order-form/order-form.component';
import { BillingDebitConfigComponent } from 'src/app/modules/billing/components/debit-config/debit-config.component';

// Views
import { BillingBillingComponent } from 'src/app/modules/billing/views/billing/billing.component';
import { BillingInvoicesComponent } from 'src/app/modules/billing/views/invoices/invoices.component';
import { BillingOrdersComponent } from 'src/app/modules/billing/views/orders/orders.component';
import { BillingServicesComponent } from 'src/app/modules/billing/views/services/services.component';
import { BillingMovementsComponent } from 'src/app/modules/billing/views/movements/movements.component';

@NgModule({
  declarations: [
    // Components
    BillingResumeComponent,
    BillingServiceComponent,
    BillingMovementComponent,
    BillingDataFormComponent,
    BillingDataUpdateFormComponent,
    BillingInvoiceComponent,
    BillingInvoiceDetailsComponent,
    BillingOrderComponent,
    BillingOrderDetailsComponent,
    HostbiAccountOrderComponent,
    BillingOrderFormComponent,
    BillingDebitConfigComponent,
    
    // Views
    BillingBillingComponent,
    BillingInvoicesComponent,
    BillingOrdersComponent,
    BillingServicesComponent,
    BillingMovementsComponent,
  ],
  imports: [
    CommonModule,
    LibraryModule,
    BillingRoutingModule,
    RouterModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,

    AuthModule,
    UserModule,
  ],
  exports: [
    BillingResumeComponent,
    BillingServiceComponent,
    BillingOrdersComponent,
    BillingOrderFormComponent,

    BillingOrderComponent,
    BillingMovementComponent,
    BillingInvoiceComponent,
  ],
  providers: [
    BillingOrdersService,   
    BillingInvoicesService,    
    BillingServicesService,
    BillingPaymentsService,
    BillingMovementsService,
    BillingAccountingService,
  ]
})

export class BillingModule { }
