import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Utils
import { RoutingGuard } from 'src/app/platform/functions/routing.guard';

// Views
import { HostbiDashboardComponent } from 'src/app/modules/hostbi/views/dashboard/dashboard.component';
import { HostbiAccountsManageComponent } from 'src/app/modules/hostbi/views/accounts-manage/accounts-manage.component';
import { HostbiAccountDetailsComponent } from 'src/app/modules/hostbi/views/account-details/account-details.component';
import { HostbiServersManageComponent } from 'src/app/modules/hostbi/views/servers-manage/servers-manage.component';
import { HostbiMonitoringDetailsComponent } from 'src/app/modules/hostbi/views/monitoring-details/monitoring-details.component';

const routes: Routes = [
  { path: '', redirectTo: '/hostbi/dashboard', pathMatch: 'full' },
  { path: 'hostbi/dashboard', component: HostbiDashboardComponent, canActivate: [RoutingGuard] },
  { path: 'hostbi/accounts', component: HostbiAccountsManageComponent, canActivate: [RoutingGuard] },
  { path: 'hostbi/accounts/:id', component: HostbiAccountDetailsComponent, canActivate: [RoutingGuard] },
  { path: 'hostbi/servers', component: HostbiServersManageComponent, canActivate: [RoutingGuard] },
  { path: 'hostbi/monitoring/:id', component: HostbiMonitoringDetailsComponent, canActivate: [RoutingGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HostbiRoutingModule { }
