<ui-header>
    <ui-title>Cuentas de alojamiento</ui-title>

    <div class="ui-accounts-filters">
        <ui-field>
            <input class="ui-input" type="text" placeholder="Buscar cuenta" (keydown)="accountsSearch($event)" [(ngModel)]="accountsSearchQuery">
            
            <ui-button slot="right" (click)="accountsSearch()">
                <ui-icon slot="icon-only" name="search" type="outlined"></ui-icon>
            </ui-button>
        </ui-field>
        
        <ui-button color="primary">Crear cuenta</ui-button>
    </div>
</ui-header>

<ui-content>
    <div class="ui-accounts">
        <div class="ui-accounts-list" *ngIf="accountsData.length > 0">
            <hostbi-account-admin class="ui-account" [account]="account" *ngFor="let account of accountsData"></hostbi-account-admin>

            <ui-spinner-layer *ngIf="accountsLoading"></ui-spinner-layer>
        </div>

        <div class="ui-accounts-pagination">
            <ui-pagination [fetch]="accountsFetch" [data]="accountsPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
    </div>

    <ui-screen-loader *ngIf="accountsData.length == 0"></ui-screen-loader>
</ui-content>