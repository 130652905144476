import { Injectable } from '@angular/core';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { HostbiMonitoring, HostbiMonitoringLiveTraffic, HostbiMonitoringReport } from '../models/hostbi-monitoring';

@Injectable()
export class HostbiMonitoringService {
    public workspace = '';

    constructor(
        private apiService: ApiService,
    ) {}

    whitWorkspace(){
        this.workspace = 'workspace/';

        return this;
    }

    whitoutWorkspace(){
        this.workspace = '';

        return this;
    }

    getAll(page = 1, pageSize = 10, filters?: { search?: string; workspace?: number; }): Promise<HttpResponseData> {
        let url = 'module/hostbi/v1/' + this.workspace + 'monitoring?page=' + page + '&pageSize=' + pageSize;

        if(typeof filters !== 'undefined'){
            const filtered = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v !== null && v !== 0 && v !== ''));

            if(Object.keys(filtered).length > 0){
                url = url + '&' + this.apiService.paramsBuild(filtered);
            }
        }

        return new Promise((resolve, reject) => {
            this.apiService.request('get', url).toPromise().then((success: any) => {
                if (success.status) {
                    const data: Array<HostbiMonitoring> = [];

                    success.data.results.forEach((value: any) => {
                        data.push(new HostbiMonitoring(value));
                    });

                    resolve(this.apiService.successDataPrepare(success.data.message, data));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
    
    report(id: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/' + this.workspace + 'monitoring/' + id + '/report').toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, new HostbiMonitoringReport(success.data.report)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    livetraffic(id: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/' + this.workspace + 'monitoring/' + id + '/livetraffic').toPromise().then((success: any) => {
                if (success.status) {
                    const data: Array<HostbiMonitoringLiveTraffic> = [];

                    success.data.livetraffic.forEach((value: any) => {
                        data.push(new HostbiMonitoringLiveTraffic(value));
                    });

                    resolve(this.apiService.successDataPrepare(success.data.message, data));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
    
    ipActivity(action: 'lock'|'unlock', id: number): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            this.apiService.request('get', 'module/hostbi/v1/' + this.workspace + 'monitoring/' + id + '/ip/' + action).toPromise().then((success: any) => {
                if (success.status) {
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}
