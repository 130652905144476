import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

// Library 
import { UiAlertRef } from 'src/app/library/components/ui-alert';

// Utils
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';

// Models
import { Module } from 'src/app/platform/models/module';
import { User } from 'src/app/modules/user/models/user';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { ModuleService } from 'src/app/platform/services/module.service';
import { UsersService } from 'src/app/modules/user/services/users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public moduleData: Module | undefined;

  public usersSearchQuery = '';
  public usersSearchUser = 0;
  public usersSearchServer = 0;
  public usersData: Array<User> = [];
  public usersPagination: PaginationData = new PaginationData(0);
  public usersFetch = this.usersList.bind(this);
  public usersLoading = false;

  public errors = {
    init: {
      title: 'Usuarios',
      message: 'Buscá un usuario por su nombre o email.',
      icon: '',
    },
    load: {
      title: 'Cargando lista de usuarios',
      message: 'Estamos buscando la información los usuarios.',
      loading: true,
    },
    empty: {
      title: 'Usuario inexistente',
      message: 'No existe un usuario con esa búsqueda.',
    },
    error: {
      title: 'Usuarios',
      message: 'No se pudo cargar el listado de usuarios. Intentar nuevamente.',
    }
  };

  constructor(
    private router: Router,
    private uiService: UiService,
    private modulesService: ModuleService,
    private usersService: UsersService,
  ) {
    this.modulesService.current.subscribe((module: Module) => {
      this.moduleData = module
    });

    this.uiService.screenLoader(this.errors.init);
  }

  ngOnInit(): void {
    
  }

  usersSearch(event?: any){
    if(typeof event === 'undefined' || event.code == 'Enter'){
      this.usersList();
    }
  }

  usersList(page = 1): void{
    this.usersLoading = true;
    this.uiService.screenLoader(this.errors.load);

    const filters: any = {
      search: this.usersSearchQuery,
    };
    
    this.usersService.getAll(page, this.usersPagination.pageSize, filters).then((success: HttpResponseData) => {
      this.usersLoading = false;
      
      if (success.result.length > 0) {
        this.usersData = success.result;
        this.usersPagination.totalUpdate(success.result.length);
        this.usersPagination.pageUpdate(1);

        this.uiService.screenLoader(this.errors.init);
      } else {
        this.uiService.screenLoader(this.errors.empty);
      }
    }).catch(() => {
      this.usersLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }
}
