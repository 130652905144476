import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modules
import { LibraryModule } from 'src/app/library/library.module';
import { UserModule } from 'src/app/modules/user/user.module';
import { BillingModule } from 'src/app/modules/billing/billing.module';
import { WorkspacesRoutingModule } from 'src/app/modules/workspaces/workspaces-routing.module';

// Services
import { WorkspacesService } from 'src/app/modules/workspaces/services/workspaces.service';

// Components
import { WorkspaceRecordComponent } from 'src/app/modules/workspaces/components/workspace-record/workspace-record.component';
import { WorkspaceUserRecordComponent } from 'src/app/modules/workspaces/components/workspace-user-record/workspace-user-record.component';
import { WorkspacesPaymentProcessComponent } from 'src/app/modules/workspaces/components/payment-process/payment-process.component';
import { WorkspacesPaymentDetailsComponent } from 'src/app/modules/workspaces/components/payment-details/payment-details.component';

// Views
import { WorkspaceDetailsComponent } from 'src/app/modules/workspaces/views/workspace-details/workspace-details.component';
import { WorkspacesComponent } from 'src/app/modules/workspaces/views/workspaces/workspaces.component';

@NgModule({
  declarations: [   
    WorkspaceRecordComponent,
    WorkspaceUserRecordComponent,
    WorkspacesPaymentProcessComponent,
    WorkspacesPaymentDetailsComponent,

    WorkspaceDetailsComponent,
    WorkspacesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LibraryModule,
    UserModule,
    BillingModule,
    WorkspacesRoutingModule
  ],
  exports: [
    WorkspacesPaymentProcessComponent,
  ],
  providers: [
    WorkspacesService,
  ]
})
export class WorkspacesModule { }
