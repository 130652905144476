import { Component, OnInit } from '@angular/core';

// Utils
import { UiModalbox } from 'src/app/library/components/ui-modalbox';

// Models
import { HttpResponseData } from 'src/app/library/models/utils';
import { BillingData, BillingDataUpdate } from 'src/app/modules/billing//models/billing';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';

@Component({
  selector: 'app-billing-data-update-form',
  templateUrl: './data-update-form.component.html',
  styleUrls: ['./data-update-form.component.scss']
})
export class BillingDataUpdateFormComponent implements OnInit {
  public billingData: BillingData = new BillingData();
  public billingDataUpdate: () => Promise<BillingDataUpdate> = () => new Promise((resolve) => { resolve(new BillingDataUpdate('', this.billingData)); });
 
  constructor(
    private uiService: UiService,
    private uiModalbox: UiModalbox,
    private billingAccountingService: BillingAccountingService,
  ) {
    this.uiService.loader(true);

    this.billingAccountingService.get().then((success: HttpResponseData) => {
      this.uiService.loader(false);

      this.billingData = success.result;
    }).catch(() => {
      this.uiService.loader(false);
      this.uiService.snackbar('No es posible actualizar los datos de facturación en este momento. Estamos trabajando para solucionar el inconveniente.');
      this.close();
    });
  }

  ngOnInit(): void { }

  close(){
    this.uiModalbox.close();
  }
  
  update(): void {
    this.uiService.loader(true);
    
    this.billingDataUpdate().then((success) => {
      this.uiService.loader(false);

      this.billingData = success.data;      
      this.uiService.dialog('Datos actualizados', success.message, 'success');

      this.close();
    }).catch((error) => {
      this.uiService.loader(false);

      this.uiService.snackbar(error.message);
    });
  }
}
