import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Models
import { User } from 'src/app/modules/user/models/user';
import { HttpResponseData, HttpResponseError } from 'src/app/library/models/utils';
import { BillingData, BillingBalance } from 'src/app/modules/billing/models/billing';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { ApiService } from 'src/app/library/services/api.service';
import { platformUrl } from 'src/app/platform/functions/platform';

// Components
import { BillingDebitConfigComponent } from '@modules/billing/components/debit-config/debit-config.component';
import { UiAlertRef } from '@app/library/components/ui-alert';
import { isEmail } from '@app/library/utils/functions';

@Injectable()
export class BillingAccountingService {
    public balanceData = new BehaviorSubject<BillingBalance>(new BillingBalance());
    
    constructor(
        private uiService: UiService,
        private apiService: ApiService,
    ) {}

    balance(workspace?: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'module/accounting/v1/' + (workspace ?? 'workspace') + '/balance/').toPromise().then((success: any) => {
                this.balanceData.next(new BillingBalance(success.data.balance))
                
                resolve(this.apiService.successDataPrepare(success.data.message, this.balanceData.value));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data)));
            });
        });
    }

    get(workspace?: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'module/accounting/v1/' + (workspace ?? 'workspace')).toPromise().then((success: any) => {
                resolve(this.apiService.successDataPrepare(success.data.message, new BillingData(success.data.account)));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data)));
            });
        });
    }

    update(data: {
        name: string;
        tax_type: string;
        tax_number: string;
        tax_condition: string;
        address: string;
        location: number
    }, workspace?: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request(
                'put',
                'module/accounting/v1/' + (workspace ?? 'workspace'),
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare(success.data.message, new BillingData(success.data)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, new HttpResponseError(success.data.errors)));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data)));
            });
        });
    }
    
    subscription(action: 'add'|'cancel', email?: string, returnUrl?: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            const data = (action === 'add') ? { email, return_url: returnUrl } : {};
            
            this.apiService.request(
                'post',
                'module/accounting/v1/workspace/subscription/' + action,
                data,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare(success.data.message, new BillingData(success.data)));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, new HttpResponseError(success.data.errors)));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data.errors)));
            });
        });
    }

    paymentProcess(workspace: string, transaction: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request(
                'post',
                'module/accounting/v1/' + workspace + '/payments/process',
                { transaction },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
            ).toPromise().then((success: any) => {
                if(success.status){
                    resolve(this.apiService.successDataPrepare(success.data.message, success.data));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success.data));
                }
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data.errors)));
            });
        });
    }

    debitAdd(user: User){
        this.uiService.alert({
          title: 'Débito automático',
          content: BillingDebitConfigComponent,
          buttons: [
            { text: 'Cancelar', role: 'cancel' },
            { text: 'Continuar', color: 'primary', click: (response: UiAlertRef) => {
                if(typeof response.data.email !== 'undefined' && isEmail(response.data.email)){
                    this.uiService.loader(true);
                    
                    this.subscription('add', response.data.email, platformUrl()).then((success: HttpResponseData) => {
                        this.uiService.loader(false);
            
                        if (success.status) {
                            response.close();

                            this.uiService.alert({
                                title: 'Débito automático',
                                message: `
                                    <p>Vamos a abrir una nueva ventana para que puedas completá el proceso.
                                    <strong>Revisá que tu navegador no esté bloqueando las ventanas emergentes.</strong>.</p>
                                    <p>Si tenes problemas para abrir la ventana podes hacer 
                                    <a href="` + success.result.url + `" target="_blank" style="text-decoration:underline;font-weight:500;">click aca</a></p>
                                `,
                                buttons: [
                                    { text: 'Cancelar', role: 'cancel' },
                                    { text: 'Configurar', color: 'primary', click: () => { window.open(success.result.url); }}
                                ]
                            });
                        } else {
                            this.uiService.alert({ title: 'Débito automático', message: success.message });
                        }
                    }).catch((error: HttpResponseData) => {
                        this.uiService.loader(false);
                        this.uiService.alert({ title: 'Error', message: error.message });
                    });
                } else {
                    this.uiService.snackbar('Ingresá el email de tu cuenta de Mercado Pago para configurar el débito.');
                }
            }}
          ]
        });
      }
}
