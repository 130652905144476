
<ui-header>
    <ui-toolbar>
        <ui-button slot="left" color="transparent" (click)="close()">
            <ui-icon name="chevron_left" type="outlined" slot="icon-only"></ui-icon>
        </ui-button>

        <ui-title slot="left">{{order.title}}</ui-title>
    </ui-toolbar>
</ui-header>

<ui-content>
    <ui-card class="ui-form">
        <div class="ui-card-content">
            <div class="order-data">
                <dl>
                    <dt>Fecha de emisión:</dt>
                    <dd>{{order.createdAt | date: 'dd/MM/yyyy'}}</dd>
                
                    <ng-container *ngIf="order.isPending">
                        <dt>Fecha de vencimiento:</dt>
                        <dd>{{order.expiresOn | date: 'dd/MM/yyyy'}}</dd>
                    </ng-container>
                
                    <dt>{{order.typeName}} Nº:</dt>
                    <dd>{{order.number}}</dd>
                  </dl>
            </div>
            
            <div class="order-details">
                <div class="order-details-head">
                    <div class="ui-detail-el">
                        <div class="ui-movement-el">Detalle</div>
                        <div class="ui-quantity-el">Cant</div>
                        <div class="ui-price-el">Valor</div>
                        <div class="ui-tax-el">IVA 21%</div>
                        <div class="ui-total-el">Total</div>
                    </div>
                </div>

                <div class="order-details-body">
                    <div class="ui-detail-el" *ngFor="let detail of order.details">
                        <div class="ui-movement-el">
                            {{detail.title}}
                            <span class="ui-movement-data-el">{{detail.quantity}} x $&nbsp;{{detail.price | number : '1.2-2'}}</span>
                        </div>
                        <div class="ui-quantity-el">{{detail.quantity}}</div>
                        <div class="ui-price-el">$&nbsp;{{detail.price | number : '1.2-2'}}</div>
                        <div class="ui-tax-el">$&nbsp;{{detail.taxAmount | number : '1.2-2'}}</div>
                        <div class="ui-total-el">$&nbsp;{{detail.amount | number : '1.2-2'}}</div>
                    </div>
                </div>

                <div class="order-details-foot">
                    <div class="ui-detail-el">
                        <div class="ui-text-el">Subtotal</div>
                        <div class="ui-total-el">$&nbsp;{{order.subtotal | number : '1.2-2'}}</div>
                    </div>

                    <div class="ui-detail-el">
                        <div class="ui-text-el">IVA 21%</div>
                        <div class="ui-total-el">$&nbsp;{{order.taxAmount | number : '1.2-2'}}</div>
                    </div>

                    <div class="ui-detail-el">
                        <div class="ui-text-el">Total</div>
                        <div class="ui-total-el">$&nbsp;{{order.total | number : '1.2-2'}}</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="ui-card-footer">
            <ui-button color="primary" (click)="pay(order)" *ngIf="order.isPending && !balance.debitStatus">Pagar</ui-button>    
            <ui-button color="secondary" (click)="close()">Cerrar</ui-button>    
        </div>
    </ui-card>
</ui-content>