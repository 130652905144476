import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

// Models
import { DataRecords, HttpResponseData, HttpResponseError } from 'src/app/library/models/utils';
import { CryptoJSAesEncrypt } from 'src/app/library/utils/functions';
import { isDefined } from 'src/app/library/utils/functions';

// Models
import { User } from 'src/app/modules/user/models/user';

// Services
import { ApiService } from 'src/app/library/services/api.service';

@Injectable()
export class UsersService {
    constructor(
        private apiService: ApiService,
    ) { }

    //{user}/password/change
    getAll(page = 1, pageSize = 10, filters?: { search?: string; }): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => {
            const params: string = this.apiService.paramsBuild(this.apiService.paramsFilter(filters));
    
            this.apiService.request('get', 'v1/users?page=' + page + '&page_size=' + pageSize + ((params !== '') ? '&' + params : '')).toPromise().then((success: any) => {
                if (success.status) {
                    const usersData: Array<User> = [];
    
                    success.data.results.forEach((value: any) => {
                        usersData.push(new User(value));
                    });
    
                    resolve(this.apiService.successDataPrepare(success.data.message, usersData));
                } else {
                    reject(this.apiService.errorDataPrepare(success.data.message, success));
                }
            }, (error) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }

    get(user: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('get', 'v1/users/' + user).toPromise().then((success: any) => {
                resolve(this.apiService.successDataPrepare(success.data.message, new User(success.data.user)));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
    
    passwordReset(user: string): Promise<HttpResponseData> {
        return new Promise((resolve, reject) => { 
            this.apiService.request('post', 'v1/users/' + user + '/password/reset').toPromise().then((success: any) => {
                resolve(this.apiService.successDataPrepare(success.data.message, success.data.password));
            }, (error: any) => {
                reject(this.apiService.errorDataPrepare(error.data.message, error));
            });
        });
    }
}
