import { Component, OnInit } from '@angular/core';

// Models
import { platformUrl } from 'src/app/platform/functions/platform';
import { HttpResponseData } from 'src/app/library/models/utils';
import { PaginationData } from 'src/app/library/components/ui-pagination';
import { BillingMovement } from 'src/app/modules/billing/models/movements';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { BillingMovementsService } from 'src/app/modules/billing/services/movements.service';

@Component({
  selector: 'app-billing-movements',
  templateUrl: './movements.component.html',
  styleUrls: ['./movements.component.scss']
})
export class BillingMovementsComponent implements OnInit {
  public movementsData: Array<BillingMovement> = [];
  public movementsPagination: PaginationData = new PaginationData(0);
  public movementsFetch = this.fetch.bind(this);
  public movementsPay = this.pay.bind(this);
  public movementsLoading = false;

  public errors = {
    load: {
      title: 'Cargando movimientos',
      message: 'Estamos buscando los movimientos de tu cuenta.',
      icon: 'fact_check',
      loading: true,
    },
    empty: {
      title: 'Sin movimientos',
      message: 'Todavia no hay movimientos generados en tu cuenta.',
      icon: 'fact_check',
    },
    error: {
      title: 'Sin movimientos',
      message: 'No pudimos cargar los movimientos de tu cuenta. Estamos trabajando para solucionar este problema.',
      icon: 'fact_check',
    }
  };  

  constructor(
    private uiService: UiService,
    private movementsService: BillingMovementsService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  fetch(page = 1): void{
    this.movementsLoading = true;
    this.uiService.screenLoader(this.errors.load);

    this.movementsService.getAll(page, this.movementsPagination.pageSize).then((success: HttpResponseData) => {
      this.movementsLoading = false;

      if (success.result.records.length > 0) {
        this.movementsData = success.result.records;
        this.movementsPagination.totalUpdate(success.result.recordsTotal);
        this.movementsPagination.pageUpdate(page);
      } else {
        this.uiService.screenLoader(this.errors.empty);
      }
    }).catch(() => {
      this.movementsLoading = false;
      this.uiService.screenLoader(this.errors.error);
    });
  }

  pay(movement: BillingMovement): void {
    this.uiService.loader(true);
    
    this.movementsService.paymentLink(movement.id, platformUrl('billing/movements')).then((success: HttpResponseData) => {   
      this.uiService.loader(false);
      this.uiService.dialog('Pagar', 'Vamos a abrir una nueva ventana para que puedas realizar el pago.');
              
      window.open(success.result, '_blank');
    }).catch((error: HttpResponseData) => {
      this.uiService.loader(false);
      this.uiService.dialog('Pagar', error.message, 'error');
    });
  }
}
