import moment from "moment";
import { Component, Input, OnInit, Type } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Utils
import { UiModalbox } from 'src/app/library/components/ui-modalbox';
import { formValidate, formValidateInit } from 'src/app/library/utils/form-validators';

// Models
import { DataMeta, HttpResponseData } from 'src/app/library/models/utils';
import { HostbiAccount } from 'src/app/modules/hostbi/models/hostbi-account';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiAccountsService } from 'src/app/modules/hostbi/services/hostbi-accounts.service';

@Component({
  selector: 'app-hostbi-account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss']
})
export class HostbiAccountDeleteComponent implements OnInit {
  @Input() account = new HostbiAccount();

  public motivesData: Array<DataMeta> = [
    new DataMeta(1, 'No continuare con el proyecto asociado a este servicio'),
    new DataMeta(2, 'El servicio no cumple mis expectativas'),
    new DataMeta(3, 'El servicio presenta continuas fallas e intermitencias'),
    new DataMeta(4, 'Disconformidad con el soporte técnico'),
    new DataMeta(5, 'El servicio es costoso para mi necesidad'),
  ];  

  public recommendationsData: Array<DataMeta> = [
    new DataMeta(1, 'Si, seguro'),
    new DataMeta(2, 'Probablemente'),
    new DataMeta(3, 'Quizás'),
    new DataMeta(4, 'Probablemente no'),
    new DataMeta(5, 'Definitivamente no'),
  ];
  
  public formData: FormGroup = new FormGroup({});
  public formValidates: any;
  public formInputsErrors: object = {
    motive: { required: 'Seleccioná un motivo' },
    recommendation: { },
    note: { },
  };

  constructor(
    private uiService: UiService,
    private uiModalbox: UiModalbox,
    private formBuilder: FormBuilder,
    private hostbiAccountsService: HostbiAccountsService,
  ) {
    this.formData = this.formBuilder.group({
      motive: new FormControl('', [Validators.required]),
      recommendation: new FormControl('', [Validators.required]),
      note: new FormControl('', [Validators.required]),
    });

    this.formValidates = formValidateInit(this.formData);
  }

  ngOnInit(): void { }

  close(){
    this.uiModalbox.close();
  }

  delete(): void {
    formValidate(this.formInputsErrors, this.formData, this.formValidates);    
    
    if (this.formData.valid) {
      this.uiService.alert({
        width: 450,
        title: 'Solicitar la baja',
        message: '<p>ATENCIÓN: Si continúas, tu cuenta <strong>' + this.account.domain + '</strong> comenzará el proceso de baja. Asegurate de haber realizado un respaldo de toda tu información.</p>',
        type: 'alert',
        buttons: [
          { text: 'Cancelar', role: 'cancel' },
          { text: 'Solicitar baja', role: 'accept', color: 'primary-red', click: () => {
            this.uiService.loader(true);

            this.hostbiAccountsService.whitWorkspace().cancellation(this.account.id, {
              motive: this.formData.value.motive,
              recommendation: this.formData.value.recommendation,
              note: this.formData.value.note,
            }).then((success: HttpResponseData) => {
              if (success.status) {
                this.close();

                this.account.cancellation = true;
                this.account.cancellationDate = success.result.cancellationDate;
              }

              this.uiService.loader(false);
              this.uiService.dialog('Baja solicitada', 'Ya programamos la baja de tu cuenta, la misma se realizará el día ' + moment(this.account.cancellationDate).format('DD/MM/YYYY'), 'success');
            }, () => {
              this.uiService.loader(false);
              this.uiService.dialog('Solicitar la baja', 'No es posible solicitar la baja en este momento. Estamos trabajando para solucionar el inconveniente.', 'error');
            });
          }}
        ]
      });
    }
  }
}
