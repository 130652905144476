<!--<ui-header>
    <ui-title>Cuentas</ui-title>

    <div class="ui-workspaces-filters"></div>
</ui-header>-->

<ui-content>
    <div class="ui-workspaces">
        <div class="ui-workspaces-search">
            <ui-screen-loader></ui-screen-loader>

            <div class="ui-input-el">
                <ui-field>
                    <input class="ui-input" type="text" placeholder="Buscar cuenta" (keydown.enter)="workspacesSearch($event)" [(ngModel)]="workspacesSearchQuery">
                    
                    <ui-button slot="right" (click)="workspacesSearch()">
                        <ui-icon slot="icon-only" name="search" type="outlined"></ui-icon>
                    </ui-button>
                </ui-field>

                <ui-button color="tertiary" [matMenuTriggerFor]="contextMenu">
                    <ui-icon name="more_vert" type="outlined" slot="icon-only"></ui-icon>
                </ui-button>

                <mat-menu #contextMenu="matMenu">
                    <button mat-menu-item (click)="paymentDetails()">Detalles de cobro</button>
                    <button mat-menu-item (click)="paymentProcess()">Procesar un cobro</button>
                </mat-menu>
            </div>        
        </div>        
        
        <div class="ui-workspaces-list" *ngIf="workspacesData.length > 0">
            <workspace-record class="ui-account" [workspace]="workspace" [details]="workspacePreviewBind" *ngFor="let workspace of workspacesData"></workspace-record>

            <ui-spinner-layer *ngIf="workspacesLoading"></ui-spinner-layer>
        </div>

        <div class="ui-workspaces-pagination">
            <ui-pagination [fetch]="workspacesFetch" [data]="workspacesPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
    </div>
</ui-content>