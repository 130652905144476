import { Component, Input, OnInit } from '@angular/core';

// Models
import { BillingOrder } from 'src/app/modules/billing/models/orders';
import { BillingBalance } from 'src/app/modules/billing/models/billing';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { BillingAccountingService } from 'src/app/modules/billing/services/accounting.service';

// Components
import { BillingOrderDetailsComponent } from 'src/app/modules/billing/components/order-details/order-details.component';

@Component({
  selector: 'billing-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class BillingOrderComponent implements OnInit {
  @Input() order: BillingOrder = new BillingOrder();
  @Input() pay: (order: BillingOrder) => void = (): void => { };
  
  public balance: BillingBalance = new BillingBalance();

  constructor(
    private uiService: UiService,
    private billingAccountingService: BillingAccountingService
  ) {
    this.billingAccountingService.balanceData.subscribe((balance) => {
      this.balance = balance;
    });
  }

  ngOnInit(): void { }

  details(){
    this.uiService.modalbox({
      component: BillingOrderDetailsComponent,
      backdrop: true,
      data: { 
        order: this.order,
        pay: this.pay
      }
    });
  }
}
