<ui-content>
    <div class="ui-users">
        <div class="ui-users-search">
            <ui-screen-loader></ui-screen-loader>

            <div class="ui-input-el">
                <ui-field>
                    <input class="ui-input" type="text" placeholder="Buscar usuario" (keydown.enter)="usersSearch($event)" [(ngModel)]="usersSearchQuery">
                    
                    <ui-button slot="right" (click)="usersSearch()">
                        <ui-icon slot="icon-only" name="search" type="outlined"></ui-icon>
                    </ui-button>
                </ui-field>
            </div>        
        </div>        
        
        <div class="ui-users-list" *ngIf="usersData.length > 0">
            <user-record class="ui-account" [user]="user" *ngFor="let user of usersData"></user-record>

            <ui-spinner-layer *ngIf="usersLoading"></ui-spinner-layer>
        </div>

        <div class="ui-users-pagination">
            <ui-pagination [fetch]="usersFetch" [data]="usersPagination" [pageSizeOptions]="[10, 20, 50, 100]"></ui-pagination>
        </div>
    </div>
</ui-content>