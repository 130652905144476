import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Utils
import { isDomain } from 'src/app/library/utils/functions';
import { track } from 'src/app/platform/functions/marketing';
import { HttpResponseData } from 'src/app/library/models/utils';
import { formValidate, formValidateInit, validateDomain } from 'src/app/library/utils/form-validators';

// Models
import { BillingOrderDetail } from 'src/app/modules/billing/models/orders';
import { HostbiService } from 'src/app/modules/hostbi/models/hostbi-service';

// Services
import { UiService } from 'src/app/library/services/ui.service';
import { HostbiDomainsService } from 'src/app/modules/hostbi/services/hostbi-domains.service';
import { HostbiServicesService } from 'src/app/modules/hostbi/services/hostbi-services.service';

@Component({
  selector: 'hostbi-account-order',
  templateUrl: './account-order.component.html',
  styleUrls: ['./account-order.component.scss']
})
export class HostbiAccountOrderComponent implements OnInit {
  @Input() order: Array<BillingOrderDetail> = [];
  @Output() orderChange: EventEmitter<Array<BillingOrderDetail>> = new EventEmitter();

  public step = 1;
  public params: any;
  public loadData = false;
  public servicesData: Array<HostbiService> = [];

  private domainTimer: any;
  public domainSpinner = false;
  public domainVerified = false;
  public domainService = new HostbiService();
  public hostingPlans: Array<HostbiService> = [];

  public formData: FormGroup;
  public formValidates: any;
  public formInputsErrors: object = {
    service: {
      required: 'Indicá el plan para la cuenta'
    },
    domainStatus: {
      required: 'Indicanos si ya dispones un dominio o si queres registrar uno nuevo'
    },
    domain: {
      required: 'Ingresá el dominio para la cuenta',
      invalid:  'El dominio es inválido',
      registered:  'Este dominio no puede ser utilizado',
      timeout:  'No podemos validar el dominio, ingresalo nuevamente',
      exist: 'El domino no está disponible. intenta con otra alternativa.'
    },
  };

  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private hostbiDomainsService: HostbiDomainsService,
    private hostbiServicesService: HostbiServicesService,
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params = params;
    });
    
    this.formData = this.formBuilder.group({
      domainStatus: new FormControl('', [Validators.required]),
      domain: new FormControl('', [Validators.required, validateDomain()]),
      service: new FormControl('', [Validators.required]),
    });

    this.formValidates = formValidateInit(this.formData);

    this.uiService.screenLoader({
      title: 'Cargando datos',
      message: 'Estamos buscando los planes disponibles para tu nueva cuenta.',
      icon: 'sentiment_satisfied',
      loading: true,
    });

    this.hostbiServicesService.plans(['hosting', 'domain'], 'ARS').then((success) => {
      this.loadData = true;

      this.servicesData = success.result;
      this.hostingPlans = success.result.filter((item: HostbiService) => item.model === 'hosting');

      if(typeof this.params['plan'] !== 'undefined' && Number(this.params['plan']) > 0){
        const service = this.servicesData.find((item) => { return Number(item.id) === Number(this.params['plan']); });
  
        this.formData.patchValue({ service: service });
      }
    }).catch(() => {
      this.uiService.screenLoader({
        title: 'Oops!',
        message: 'Estamos experimentando algunas dificultades en este momento. Te pedimos que intentes nuevamente actualizando la página.',
        icon: 'sentiment_very_dissatisfied',
        loading: false,
      });
    });
  }

  get domainRegister(): boolean {
    return this.formData.value.domainStatus === 'register';
  }

  ngOnInit(): void {}

  continue(): void{
    if(this.step === 1){
      this.step = 2;
    } else if(this.step === 2) {
      formValidate(this.formInputsErrors, this.formData, this.formValidates);
      
      if (this.formData.valid) {
        if(this.domainRegister && this.domainService.id > 0){
          this.order.push(new BillingOrderDetail({
            item: this.domainService.name,
            details: this.domainService.detail + this.formData.value.domain,
            unity: 'un',
            quantity: 1,
            price: this.domainService.price,
            amount: this.domainService.price,
            request: { 
              action: 'domain-register',
              module: 'hostbi',
              data: { 
                domain: this.formData.value.domain,
                type: 'hosting',
                service: this.domainService.id
              }
            }
          }));
        }
        
        this.order.push(new BillingOrderDetail({
          item: this.formData.value.service.name,
          details: this.formData.value.service.detail + ' para el dominio ' + this.formData.value.domain,
          unity: 'un',
          quantity: 1,
          price: this.formData.value.service.price,
          amount: this.formData.value.service.price,
          request: { 
            action: 'account-create',
            module: 'hostbi',
            data: { 
              domain: this.formData.value.domain,
              type: 'hosting',
              service: this.formData.value.service.id
            }
          }
        }));

        this.orderChange.emit(this.order);

        track('order_register_initiate');
      }
    }
  }

  domainVerify(): void {
    clearTimeout(this.domainTimer);

    this.domainVerified = false;
    this.domainService = new HostbiService();

    this.domainTimer = setTimeout(() => {
      const domain = this.formData.value.domain;

      if (isDomain(domain)) {
        this.domainSpinner = true;
        this.formData.controls['domain'].setErrors(null);

        this.hostbiDomainsService[this.domainRegister ? 'checkAvailability' : 'checkExist'](domain).then((success: HttpResponseData) => {
          this.domainSpinner = false;

          if(this.domainRegister){
            if (success.result.status) {
              this.domainService = success.result.service;
              this.domainVerified = true;
            } else {
              this.formData.controls['domain'].setErrors({exist: true});
            }
          } else {
            if (success.result.status) {
              this.formData.controls['domain'].setErrors({registered: true});
            }
          }

          formValidate(this.formInputsErrors, this.formData, this.formValidates);
        }).catch(() => {
          this.domainSpinner = false;
          this.formData.controls['domain'].setErrors({timeout: true});

          formValidate(this.formInputsErrors, this.formData, this.formValidates);
        });
      }
    }, 1000);
  }
}
