import moment from "moment";

// Models
import { camelcase } from 'src/app/library/utils/functions';
import { HostbiStatus } from 'src/app/modules/hostbi/models/hostbi';
import { HostbiPlan } from 'src/app/modules/hostbi/models/hostbi-plan';
import { Workspace } from 'src/app/modules/workspaces/models/workspace';
import { BillingService } from 'src/app/modules/billing/models/services';
import { HostbiServer } from 'src/app/modules/hostbi/models/hostbi-server';
import { DomainRecordType } from "src/app/modules/hostbi/models/hostbi-domain";

export class HostbiAccount {
    id = 0;
    workspace: Workspace = new Workspace();
    server: HostbiServer = new HostbiServer();
    plan: HostbiPlan = new HostbiPlan();
    domain = '';
    user = '';
    type: 'hosting'|'app'|'vps'|'dedicated' = 'hosting';
    password = '';
    status: HostbiStatus = new HostbiStatus();
    autossl = false;
    storage: HostbiAccountResource = new HostbiAccountResource();
    bandwidth: HostbiAccountResource = new HostbiAccountResource();
    emails: Array<HostbiAccountEmail> = [];
    report: Array<any> = [];
    services: Array<HostbiAccountService> = [];
    charges: Array<BillingService> = [];
    domainRecords: Array<HostbiAccountDomain> = [];
    dateChange = ''; 
    cancellation = false; 
    cancellationDate: Date = moment().toDate();
    issues: Array<HostbiAccountIssues> = [];

    constructor(parameters?: any) {
        if(typeof parameters !== 'undefined'){
            this.id = parameters.id;
            this.workspace = new Workspace(parameters.workspace);
            this.server = new HostbiServer(parameters.server);
            this.plan = new HostbiPlan(parameters.plan);
            this.domain = parameters.domain;
            this.user = parameters.user;
            this.type = parameters.type;
            this.password = parameters.password;
            this.status = new HostbiStatus(parameters.status);
            this.autossl = parameters.autossl;            
            this.storage = new HostbiAccountResource(parameters.storage);
            this.bandwidth = new HostbiAccountResource(parameters.bandwidth);
            this.report = parameters.report;
            
            this.dateChange = parameters.dateChange;

            this.cancellation = parameters.cancellation;
            this.cancellationDate = moment((parameters.cancellation_date != null) ? parameters.cancellation_date : '').toDate();

            if(typeof parameters.emails !== 'undefined' && parameters.emails.length > 0){
                parameters.emails.forEach((item: any) => {
                    this.emails.push(new HostbiAccountEmail(item));
                });
            }

            if(typeof parameters.services !== 'undefined' && parameters.services.length > 0){
                parameters.services.forEach((item: any) => {
                    this.services.push(new HostbiAccountService(item));
                });
            }

            if(typeof parameters.charges !== 'undefined' && parameters.charges.length > 0){
                parameters.charges.forEach((item: any) => {
                    this.charges.push(new BillingService(item));
                });
            }
            console.log(parameters);
            if(typeof parameters.summary.issues !== 'undefined' && parameters.summary.issues.length > 0){
                parameters.summary.issues.forEach((item: string) => {
                    this.issues.push(new HostbiAccountIssues(item));
                });
            }            
        }
    }

    get isCancellationDay(): boolean{
        return moment(this.cancellationDate).isSame(moment(), 'day');
    }
}

export class HostbiAccountIssues {
    name = '';
    key = '';
    ref = '';
    description = '';
    solution = '';

    private types: any = {
        storage_exceeded: {
            name: 'Almacenamiento exedido',
            ref: '',
            description: 'Se alcanzo el límite de almacenamiento. Tu cuenta puede experimentar inestabilidad y no podrás enviar ni recibir correos por no disponer de espacio para ello.',
            solution: '<a href="https://clousis.com/soporte/hosting-cpanel/informacion-sobre-almacenamiento">Ver sugerencias</a>',
        },
        storage_overloaded: {
            name: 'Almacenamiento cercano al límite',
            ref: '',
            description: 'La cuenta esta próxima a alcanzar el límite de almacenamiento, si esto sucede, tu sitio puede experimentar inestabilidad y no podrás enviar ni recibir correos por no disponer de espacio para ello.',
            solution: '<a href="https://clousis.com/soporte/hosting-cpanel/informacion-sobre-almacenamiento">Ver sugerencias</a>',
        },
        bandwidth_exceeded: {
            name: 'Transferencia exedida',
            ref: '',
            description: 'Se alcanzo el límite de transferencia. Durante lo que resta del mes tu cuenta no mostrara el sitio web a los visitantes ya que no dispones de ancho de banda para ello.',
            solution: '<a href="https://clousis.com/soporte/hosting-cpanel/informacion-sobre-transferencia/">Ver sugerencias</a>',
        },
        bandwidth_overloaded: {
            name: 'Transferencia cercana al límite',
            ref: '',
            description: 'La cuenta esta próxima a alcanzar el límite de transferencia, si esto sucede, lo que reste del mes, no se mostrara el sitio web a los visitantes ya que no dispones de ancho de banda para ello.',
            solution: '<a href="https://clousis.com/soporte/hosting-cpanel/informacion-sobre-transferencia/">Ver sugerencias</a>',
        },
        email_exceeded: {
            name: '',
            ref: '',
            description: '',
            solution: '',
        },
        email_overloaded: {
            name: '',
            ref: '',
            description: '',
            solution: '',
        },
        domain_moved: {
            name: '',
            ref: '',
            description: '',
            solution: '',
        }
    };

    constructor(parameters?: string) {
        if(typeof parameters !== 'undefined'){
            this.key = parameters;            
            this.name = this.types[parameters] ?? '';            
            this.ref = this.types[parameters] ?? '';
            this.description = this.types[parameters] ?? '';
            this.solution = this.types[parameters] ?? '';
        }
    }
}
export class HostbiAccountResource {
    total = 0;
    usage = 0;
    usagePercent = 0;
    unity = '';

    constructor(parameters?: any) {
        if(typeof parameters !== 'undefined'){
            this.total = parameters.total;
            this.usage = parameters.usage;
            this.usagePercent = parameters.usage_percent;   
            this.unity = parameters.unity;
        }
    }
}

export class HostbiAccountService {
    id = 0;
    name = '';
    price = 0;
    action: HostbiAccountServiceAction = new HostbiAccountServiceAction();
    actionParams: object = {};

    constructor(parameters?: any) {
        if(typeof parameters !== 'undefined'){
            this.id = parameters.id;
            this.name = parameters.name;
            this.price = parameters.price;
            this.action = new HostbiAccountServiceAction(parameters.action);
            this.actionParams = parameters.parameters;
        }
    }
}

export class HostbiAccountServiceAction {
    id = '';
    name = '';
    parameters: object = {};

    constructor(parameters?: any) {
        if(typeof parameters !== 'undefined'){
            this.id = parameters.id;
            this.name = parameters.name;
            this.parameters = parameters.parameters;
        }
    }
}
export class HostbiAccountDomain {
    id = 0;
    domain = '';
    status = 'pending';
    nameServers: Array<string> = [];
    dns: Array<HostbiAccountDomainRecord> = [];
    removable = false;
    sslStatus = '';

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    if(key === 'dns'){
                        parameters[key].forEach((item: any) => {
                            this.dns.push(new HostbiAccountDomainRecord(item));
                        });
                    } else {
                        (this as any)[camelcase(key)] = value;
                    }
                }
            }
        }
    }
}

export interface HostbiAccountDomainEntity {
    domain: string;
    pathPublic: boolean;
    pathValue?: string;
}

export class HostbiAccountDomainRecord {
    id = 0;
    type: DomainRecordType = 'A';
    name = '';
    content = '';
    ttl = 1; // 60-86400, or 1 for 'automatic'
    priority = 0; // 0-65535 (Required for MX, SRV, URI records)
    proxied = false;
    deleted = false;

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = (key === 'proxied') ? value === 1 : value;
                }
            }
        }
    }
}

export interface HostbiAccountDomainRecordEntity {
    type?: DomainRecordType;
    name?: string;        
    content?: string;
    ttl?: number;
    priority?: number;
    proxied?: boolean|1|0;
}

export class HostbiAccountEmail {
    email = '';
    total = 0;
    usage = 0;
    usagePercent = 0;
    unity = '';

    constructor(parameters?: Record<string, any>) {
        if(typeof parameters !== 'undefined'){
            for(const [key, value] of Object.entries(parameters)) {
                if (typeof Object.prototype.hasOwnProperty.call(parameters, key) !== 'undefined') {
                    (this as any)[camelcase(key)] = value;
                }
            }
        }
    }

    get domain(): string{
        return this.email.split('@').pop() || '';
    }
}