import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

// Models
import { Workspace } from 'src/app/modules/workspaces/models/workspace';
import { HttpResponseData, HttpResponseError } from 'src/app/library/models/utils';

// Services
import { ApiService } from 'src/app/library/services/api.service';
import { BillingOrder, BillingOrderDetail } from 'src/app/modules/billing/models/orders';

@Injectable({
  providedIn: 'root'
})
export class WorkspacesService {
  public workspace: BehaviorSubject<number|null> = new BehaviorSubject<number|null>(null);
  
  constructor(
    private apiService: ApiService,
  ) { }  

  getAll(page = 1, pageSize = 10, filters?: { search?: string; }): Promise<HttpResponseData> {
    return new Promise((resolve, reject) => {
        const params: string = this.apiService.paramsBuild(this.apiService.paramsFilter(filters));

        this.apiService.request('get', 'v1/workspaces?page=' + page + '&page_size=' + pageSize + ((params !== '') ? '&' + params : '')).toPromise().then((success: any) => {
            if (success.status) {
                const workspacesData: Array<Workspace> = [];

                success.data.results.forEach((value: any) => {
                    workspacesData.push(new Workspace(value));
                });

                resolve(this.apiService.successDataPrepare(success.data.message, workspacesData));
            } else {
                reject(this.apiService.errorDataPrepare(success.data.message, success));
            }
        }, (error) => {
            reject(this.apiService.errorDataPrepare(error.data.message, error));
        });
    });
  }

  get(id: string): Promise<any> {
    const promise = new Promise((resolve, reject) => {
       
        this.apiService.request('get', 'v1/workspaces/' + id).toPromise().then((success: any) => {
            if (success.status) {
                const workspaceData = new Workspace(success.data.workspace);

                resolve(this.apiService.successDataPrepare(success.data.message, workspaceData));
            } else {
                reject(this.apiService.errorDataPrepare(success.data.message, success));
            }
        }, (error) => {
            reject(this.apiService.errorDataPrepare(error.data.message, error));
        });
    });

    return promise;
  }

  orderGenerate(data: {
    type: 'free'|'demo'|'billeable';
    details: Array<BillingOrderDetail>;
    return_url: string;
  }): Promise<HttpResponseData> {
    return new Promise((resolve, reject) => { 
        this.apiService.request(
            'post',
            'v1/workspaces/order/generate',
            data,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        ).toPromise().then((success: any) => {
            if(success.status){
              const data = {
                order: new BillingOrder(success.data.order),
                paymentLink: success.data.payment_link
              }
              resolve(this.apiService.successDataPrepare(success.data.message, data));
            } else {
              reject(this.apiService.errorDataPrepare(success.data.message, new HttpResponseError(success.data.errors)));
            }
        }, (error: any) => {
            reject(this.apiService.errorDataPrepare(error.data.message, new HttpResponseError(error.data.errors)));
        });
    });
  }
}
