<ui-header>
    <ui-title>Detalles de la cuenta {{account.domain}}</ui-title>
</ui-header>

<ui-content>
    <div class="ui-account-details" *ngIf="account.id > 0">

        <ui-note icon="cancel" color="red" *ngIf="account.cancellation">
            <p><strong>Solicitaste la baja de esta cuenta. La mista se encuentra programada para el día {{account.cancellationDate | date: 'dd/MM/YYYY'}}</strong></p>
            <p>Recordá que, al ejecutarse la baja, se eliminará toda la información asociada de forma irreversible, asegurate de haber realizado un respaldo de toda tu información. La cancelación del servicio no genera devolución del pago por el período no utilizado.</p>
            <ui-button (click)="cancellationAbort()" color="secondary-red" *ngIf="!account.isCancellationDay">Cancelar baja</ui-button>
        </ui-note>

        <div class="ui-left-el">
            
            <button class="ui-account-action" (click)="login()">
                <div><ui-icon name="tune" type="outlined"></ui-icon> Acceder al panel de hosting cPanel</div>
            </button>

            <!--<button class="ui-account-action" (click)="planUpdate()">
                <div><ui-icon name="view_carousel" type="outlined"></ui-icon> Cambiar de plan</div>
            </button>-->     
            
            <ui-card class="ui-account-emails">
                <ui-header (click)="blocksExpand['emails'] = !blocksExpand['emails']">
                    <ui-title><ui-icon name="email" type="outlined"></ui-icon>Cuentas de correo</ui-title>

                    <ui-icon [name]="(!blocksExpand['emails'] ) ? 'expand_more' : 'expand_less'" type="outlined" slot="icon-only"></ui-icon>
                </ui-header>

                <div class="ui-list-el" *ngIf="account.emails.length > 0 && blocksExpand['emails']">
                    <hostbi-email-record [account]="account.id" [email]="item" *ngFor="let item of account.emails"></hostbi-email-record>
                </div>

                <div class="ui-empty-el" *ngIf="account.emails.length === 0 && blocksExpand['emails']">Aun no existent cuentas de correo</div>
            </ui-card>
            
            <ui-card class="ui-account-domains">
                <ui-header (click)="blocksExpand['domains'] = !blocksExpand['domains']">
                    <ui-title><ui-icon name="dns" type="outlined"></ui-icon>Dominios</ui-title>

                    <ui-icon [name]="(!blocksExpand['domains'] ) ? 'expand_more' : 'expand_less'" type="outlined" slot="icon-only"></ui-icon>
                </ui-header>

                <div class="ui-list-el" *ngIf="blocksExpand['domains']">
                    <div class="ui-account-domains-content">
                        Estos dominios estan asociados a tu cuenta. desde acá podes modificar los registros DNS.
                        
                        <ui-button (click)="domainAdd()" color="secondary">Agregar nuevo dominio</ui-button>
                    </div>                    
                    
                    <ng-container *ngFor="let domain of account.domainRecords">
                        <ui-divider (click)="blocksExpand[domain.domain] = !blocksExpand[domain.domain]">
                            <ui-icon [name]="(blocksExpand[domain.domain]) ? 'expand_more' : 'chevron_right'" type="outlined" slot="left"></ui-icon>
                            
                            Registros DNS de {{domain.domain}}
                        </ui-divider>
                        
                        <ng-container *ngIf="domain.dns.length > 0 && blocksExpand[domain.domain]">
                            <ui-note icon="dns" class="ui-name-servers" *ngIf="domain.status === 'pending'">
                                <strong>El dominio no se encuentra apuntando al servidor</strong>, es necesario que puedas configurar los siguientes NS en tu dominio. Para saber como realizar este cambio podes obtener <a href="https://clousis.com/soporte/dominios/delegacion-de-dominios-en-nic-ar/" target="_blank">más información</a>
                                
                                <ul>
                                    <li *ngFor="let ns of domain.nameServers">{{ns}}</li>
                                </ul>
                            </ui-note>

                            <ui-button color="tertiary" [matMenuTriggerFor]="domainContextMenu">
                                Opciones del dominio
                            </ui-button>
            
                            <mat-menu #domainContextMenu="matMenu">
                                <button mat-menu-item (click)="domainSslStatus(domain)">Certificado de seguridad SSL</button>
                                <button mat-menu-item (click)="domainRecordAdd(domain)">Agregar registro DNS</button>
                                <button mat-menu-item (click)="domainDelete(domain)" *ngIf="domain.removable">Eliminar dominio</button>
                            </mat-menu>
                            
                            <ng-container *ngFor="let item of domain.dns">
                                <hostbi-domain-record [account]="account" [domain]="domain" [record]="item" *ngIf="!item.deleted"></hostbi-domain-record>
                            </ng-container>
                        </ng-container>

                        <div class="ui-empty-el" *ngIf="domain.dns.length === 0">No existent registros DNS configurados para {{domain.domain}}</div>
                    </ng-container>
                </div>
            </ui-card>

            <button class="ui-account-action" (click)="cancellation()" *ngIf="!account.cancellation">
                <div><ui-icon name="highlight_off" type="outlined"></ui-icon> Solicitar la baja</div>
            </button>
        </div>

        <div class="ui-right-el">
            <ui-card class="ui-block-el ui-account-status">
                <div class="ui-title-el">Estado de la cuenta</div>
                
                <div class="ui-status" [ngClass]="account.status.status">
                    <div class="ui-status-el" [ngClass]="account.status.status">
                        <ui-icon *ngIf="account.status.status === 'online'" name="check_circle" type="outlined"></ui-icon>
                        <ui-icon *ngIf="account.status.status === 'partial'" name="report_problem" type="outlined"></ui-icon>
                        <ui-icon *ngIf="account.status.status === 'offline'" name="error_outline" type="outlined"></ui-icon>
                    </div>

                    <p><strong>{{account.status.reason}}</strong> {{account.status.message}}</p>
                </div>

                <ui-button (click)="issues()" color="secondary-red" *ngIf="account.issues.length > 0">Ver problemas con la cuenta</ui-button>
            </ui-card>
            
            <ui-card class="ui-block-el">
                <div class="ui-title-el">Espacio de trabajo</div>

                <ul>
                    <li><ui-icon name="workspaces" type="outlined"></ui-icon> <strong>Cuenta:</strong> {{account.workspace.name}}</li>
                    <li *ngIf="account.workspace.partner.isSet()"><ui-icon name="handshake" type="outlined"></ui-icon> <strong>Partner asociado:</strong> {{account.workspace.partner.name}}</li>
                    <li><ui-icon name="person" type="outlined"></ui-icon> <strong>Usuarios de la cuenta</strong></li>
                </ul>

                <ul *ngIf="account.workspace.users.length > 0" class="blocks">
                    <li *ngFor="let user of account.workspace.users">
                        <strong>{{user.name}}</strong> ({{user.roleName}})<br>
                        {{user.email}}
                    </li>
                </ul>
            </ui-card>

            <ui-card class="ui-block-el">
                <div class="ui-title-el">Detalles de la cuenta</div>

                <ul>
                    <li><ui-icon name="public" type="outlined"></ui-icon> <strong>Dominio: </strong> {{account.domain}}</li>
                    <li><ui-icon name="dns" type="outlined"></ui-icon> <strong>Servidor: </strong> {{account.server.name}}</li>
                    <li><ui-icon name="view_carousel" type="outlined"></ui-icon> <strong>Plan: </strong> {{account.plan.name}}</li>
                    <li><ui-icon name="swap_horiz" type="outlined"></ui-icon> <strong>Transferencia mensual: </strong> {{account.bandwidth.total | number : '1.2-2'}} {{account.bandwidth.unity}}</li>
                    <li><ui-icon name="storage" type="outlined"></ui-icon> <strong>Espacio en disco: </strong> {{account.storage.total | number : '1.2-2'}} {{account.storage.unity}}</li>
                </ul>
                
                <div class="ui-stat-el" [ngClass]="{'full': account.bandwidth.usagePercent >= 100}">
                    <ui-stat-progress name="Transferencia" [value]="account.bandwidth.usage" [total]="account.bandwidth.total" [percent]="account.bandwidth.usagePercent" [unity]="account.bandwidth.unity"></ui-stat-progress>
                </div>
    
                <div class="ui-stat-el" [ngClass]="{'full': account.storage.usagePercent >= 100}">
                    <ui-stat-progress name="Almacenamiento" [value]="account.storage.usage" [total]="account.storage.total" [percent]="account.storage.usagePercent" [unity]="account.storage.unity"></ui-stat-progress>
                </div>
            </ui-card>

            <ui-card class="ui-block-el">
                <div class="ui-title-el">Datos de conexión FTP</div>

                <ul>
                    <li><ui-icon name="dns" type="outlined"></ui-icon> <strong>Host FTP: </strong> ftp.{{account.domain}}</li>
                    <li><ui-icon name="dns" type="outlined"></ui-icon> <strong>Host IP: </strong> {{account.server.ipv4}}</li>
                    <li><ui-icon name="person" type="outlined"></ui-icon> <strong>Usuario: </strong> {{account.user}}</li>
                    <li><ui-icon name="password" type="outlined"></ui-icon> <strong>Contraseña: </strong> {{account.password}}</li>
                </ul>
            </ui-card>
        </div>
    </div>

    <ui-screen-loader *ngIf="account.id <= 0"></ui-screen-loader>
</ui-content>

<!--
    25 Tickets: Problemas de correo
    10 Tickets: Wordpress
    10 Tickets: Servicio de Monitoreo
    7 Tickets: Cambios de DNS
    5 Tickets: Cpanel
    3 Tickets: Blanqueo de Contraseñas
    2 Tickets: Configuraciones
    1 Ticket: Webmail
    1 Ticket: Configuraciones
-->